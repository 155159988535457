import React from 'react';
import { Title } from './components/title'
import { Skill } from './components/skill'
import { Project, Experience, Certificates } from './components/project';
import {ThinLine} from './components/thinLine'
import { LinkedItem } from './components/item'
import { Programming_Language, Web_Dev, Database, Containerize, Monitoring, CI_CD, Cloud_System_Administration, Productive_Tools } from './contents/categories';
import { Cloud_For_Edu, Dehazing, Fire_Detection_MQ, KMS} from './contents/projects-contents';
import { HomeLab, Beharbor, Docker } from './contents/experiences-contents';
import { HPE_Linux, HPE_Docker_Kube } from './contents/certificates';


function Main() {
    return (
        <main className="min-w-[1240px] w-full">
            <div className="relative info-block">
                <div class="absolute right-[250px] mt-8 inline-flex gap-[16px] flex-col items-start">
                </div>
            </div>
            <div className="main-container relative inline-flex flex-col items-start mx-auto my-0">
                {/* Profiles */}
                <div className="profile-container padding">
                    <div className="profile-image-contect">
                        <img className="w-[240px] h-[300px] object-cover" alt="Profile" src="/img/profile.png" />
                        {/* <div className="contect">Contects</div> */}
                        <LinkedItem icon="/img/icon/linkedin-black.png" content="승언-유" link="https://www.linkedin.com/in/%EC%8A%B9%EC%96%B8-%EC%9C%A0-84b560222/"/>
                        <LinkedItem icon="/img/icon/github-black.png" content="Monologue2" link="https://github.com/Monologue2"/>
                        <LinkedItem icon="/img/icon/email-black.png" content="seyoumoon@gmail.com" link="mailto:seyoumoon@gmail.com"/>
                    </div>
                    <div className="profile-introduce">
                        <div className="profile-text text-head3">Junior Developer 유승언입니다.</div>
                        <div className="profile-text" >
                            저는 AI 디지털 트윈 연구실에서 진행한 국가 연구 프로젝트에서
                        <br/>CCTV 영상 분석의 결과물인 화재 감지 메세지를 다루며 디지털 트윈에 발을 들이게 되었습니다.
                        <br/>해당 디지털 트윈 구축 서버에 Docker, Kubernetes를 도입하여 
                        <br/>디지털 트윈 개발과 인프라 구축에 소요되는 프로비저닝 시간을 70% 이상 단축했습니다.
                        </div>
                        <div className="profile-text" >
                            이후 연구에서 Unreal Engine을 통해 가상 안개를 만들어 시뮬레이션하고
                        <br/>가상 안개 데이터를 통해 안개 제거 AI를 학습하여 복구본과 원본의 35 PSNR 이상의 유사도를 달성했습니다.
                        <br/>안개 생성 과정과 AI 학습 과정에 Jenkins와 Git Action을 도입하여 업무 프로세스의 90%를 자동화했습니다.
                        <br/>A100 DGX Station을 관리하기 위해 Grafana, Prometheus를 도입하여 서버 사용량을 모니터링했습니다.
                        </div>
                        <div className="profile-text" >
                            저는 디지털 트윈 분야에서 경험한 다양한 프로젝트들을 통해 끊임없는 학습과 성장을 중요하게 여기고,
                        <br/>Docker, Kubernetes, Jenkins와 같은 최신 기술을 습득하고 실무에 적용 가능한 개발자입니다.
                        <br/>앞으로도 AI, 클라우드, 가상 시뮬레이션 기술을 계속해서 탐구하며, 
                        <br/>새로운 도전을 마주할 때마다 적극적으로 나아갈 준비가 되어 있습니다.
                        <br/>성장을 멈추지 않는 개발자로서 현재까지도 개인 역량을 성장시키기 위해 공부를 멈추지 않고 있습니다. 감사합니다. </div>
                    </div>
                </div>

                {/* Skill */}
                <Title title="Skill"></Title>
                <div className="skill-container">
                    <Skill category="Programming Language" {...Programming_Language}/>
                    <Skill category="Web & API Development"{...Web_Dev}/>
                    <Skill category="Database"{...Database}/>
                    <Skill category="Containerize" {...Containerize}/>
                    <Skill category="Monitoring" {...Monitoring}/>
                    <Skill category="CI/CD" {...CI_CD}/>
                    <Skill category="Cloud & System Administration" {...Cloud_System_Administration}/>
                    <Skill category="Productive_Tools" {...Productive_Tools}/>
                </div>

                {/* Projects */}
                <Title title="Projects"></Title>
                <Project project={Cloud_For_Edu} />
                <ThinLine />
                <Project project={Dehazing} />
                <ThinLine />
                <Project project={Fire_Detection_MQ} />
                <ThinLine />
                <Project project={KMS} />
                
                {/* Experiences */}
                <Title title="Experiences"></Title>
                <Experience project={HomeLab} />
                <ThinLine />
                <Experience project={Beharbor} />
                <ThinLine />
                <Project project={Docker} />

                {/* Certificates */}
                <Title title="Certificates"></Title>
                <Certificates project={HPE_Linux} />
                <ThinLine />
                <Certificates project={HPE_Docker_Kube} />
            </div>
            <div className="relative info-block">
                <div class="absolute right-[250px] mt-8 inline-flex gap-[16px] flex-col items-start">
                    <div class="bottom-text relative w-[134px] mt-[-1.00px] text-white" style={{ fontFamily: 'Pretendard'}}>
                        유승언 <br />
                        seyoumoon@gmail.com <br />
                        v2024.10
                    </div>
                </div>
            </div>
        </main>
    );
}


export default Main